import * as React from "react"
import { Link } from "gatsby"

import Layout from "../templates/layout-standard"
import Seo from "../components/seo"
import SideBar from '../components/sidebar';
import cursorsecondaryicn from '../assets/img/cursor-secondary-icn.svg';

const MedicareBasics = ({ location }) => (
	<Layout location={location} heading1="Medicare Basics" heading2={["Get to know the ins and ", <span>outs of Medicare.</span>]}>
    	<Seo title="Medicare Basics" meta={[{description: 'Learn how to make the most of the various Medicare plan options available to you.'}]} bodyclass="medicare-basics turning-65 subpage" />
		
		<div className="constrained">
			<div className="content-constrained">
				<div className="flexwrap_subpage">

					<main>
						<h3>What Does Medicare Mean To You?</h3>
						<p>Medicare provides health insurance benefits for people age 65 years and older, as well as certain people under 65 with disabilities or specific medical conditions. Becoming eligible for Medicare creates a lot of new opportunities &ndash; but you need to know how to make the most of them.</p>

						<h3>What&rsquo;s your current situation?</h3>
						<nav aria-label="Medicare Basics Menu" className="medicarebasics-nav">
							<ul>
								<li><Link to="/medicare-basics/turning-65/where-to-start/"><span className="arrow black">Turning 65</span></Link></li>
								<li><Link to="/medicare-basics/65-plus/where-to-start/"><span className="arrow black">65+ <em>First Time Enrolling</em></span></Link></li>
								<li><Link to="/medicare-basics/already-on-medicare/"><span className="arrow black">Already on Medicare</span></Link></li>
							</ul>
						</nav>

						<p>Questions on Medicare? <Link to="/medicare-basics/faq/">We have answers</Link>.</p>

						<h3 className="small">Learn more about UVM Health Advantage Medicare plans:</h3>

						<div className="ctas secondary">
							<div className="flexwrap">
								<div className="secondary-cta">
									<img src={cursorsecondaryicn} alt="" width="60" height="60" className="icon" />
									<p className="head"><b>Request the FREE UVM Health Advantage Quick Guide to Medicare.</b></p>
									<div><p>Get to know your Medicare options, highlight important dates and more.</p></div>
									<Link to="/quick-guide/" className="button secondary">Get the Guide</Link>
								</div>
								<div className="secondary-cta">
									<img src={cursorsecondaryicn} alt="" width="60" height="60" className="icon" />
									<p className="head"><b>Request a UVM Health Advantage Plan Benefit kit!</b></p>
									<div><p>Get benefit details, compare plans and more when you request your FREE plan benefit kit now.</p></div>
									<Link to="/request-a-kit/" className="button secondary">Get the Kit</Link>
								</div>
							</div>
						</div>

					</main>

					<SideBar location={location} />
				</div>
			</div>
		</div>

	</Layout>
)

export default MedicareBasics
